import { animated, useSpring } from '@react-spring/web'
import { useElementSize } from '@kaliber/use-element-size'

import styles from './Expand.css'

export function ExpandHorizontal({ expanded, children, justification = 'start', layoutClassName = undefined }) {
  return <ExpandBase property='width' className={cx(styles.componentHorizontal, layoutClassName)} {...{ expanded, children, justification }} />
}

export function ExpandVertical({ expanded, children, justification = 'start', layoutClassName = undefined }) {
  return <ExpandBase property='height' className={cx(styles.componentVertical, layoutClassName)} {...{ expanded, children, justification }} />
}

function ExpandBase({ property, expanded, children, justification = 'start', className }) {
  const { ref: sizeRef, size } = useElementSize()
  const initializedRef = React.useRef(false)

  const [spring, api] = useSpring(() => ({
    [property]: expanded ? size[property] : 0,
    config: { tension: 200, friction: 25 }
  }))
  const propertyValue = size[property]

  // This makes sure you don't animate in from 0, when initially expanded
  React.useEffect(
    () => {
      if (!propertyValue) return
      if (initializedRef.current) {
        api.start({ [property]: expanded ? propertyValue : 0 })
      } else {
        api.set({ [property]: expanded ? propertyValue : 0 })
        initializedRef.current = true
      }
    },
    [propertyValue, property, expanded, api]
  )

  return (
    <animated.div
      aria-hidden={!expanded}
      style={{ [property]: spring[property].to(value => size[property] ? value : expanded ? 'auto' : 0) }}
      className={cx(styles.componentBase, justification === 'end' && styles.justifyEnd, className)}
    >
      <div ref={sizeRef}>
        {children}
      </div>
    </animated.div>
  )
}
