import { useKeenSlider } from 'keen-slider/react'
import { animated, useSpring } from '@react-spring/web'
import { useQuery } from '@tanstack/react-query'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'
import { useReportError } from '/machinery/ReportError'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { fetchWithResponseHandler } from '/machinery/fetchWithResponseHandler'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import { Loader } from '/features/buildingBlocks/Loader'
import { Error } from '/features/pageOnly/skillsMatch/buildingBlocks/Error'
import { useMySelectionMutations, useSkillsMatchUserSelection } from '/features/pageOnly/skillsMatch/buildingBlocks/MySelection'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'
import { SkillsMatchButtonToggleTransparent } from '/features/pageOnly/skillsMatch/buildingBlocks/SkillsMatchButton'
import { ImageCover } from '/features/buildingBlocks/Image'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './ProofPoints.css'

import dragIcon from '/images/icons/drag.raw.svg'

export function ProofPoints() {
  const { __ } = useTranslate()
  const language = useLanguage()

  const {
    proofPoints,
    isSuccess,
    isError,
    isFetching
  } = useProofPointsData({ language })

  return (
    <div className={styles.component}>
      {isFetching && (
        <div className={styles.loaderContainer}>
          <Loader layoutClassName={styles.loaderLayout} />
        </div>
      )}

      {isError
        ? <Error title={__`skills-match-general-error`} layoutClassName={styles.errorLayout} />
        : isSuccess && <Content items={proofPoints ?? {}} layoutClassName={styles.contentLayout} />
      }
    </div>
  )
}

function Content({ items, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      <HeadingMd h={3} title={__`heading-proof-points`} layoutClassName={styles.headingLayout} />
      <Slider layoutClassName={styles.sliderLayout} {...{ items }} />
    </div>
  )
}

function Slider({ items, layoutClassName = undefined }) {
  const { sliderRef, currentSlide, hasDragged, sliderDisabled } = useKeenSliderRefs({ slidesNum: items.length })
  const { elementRef, x, y, isHovering, setIsHovering } = useCursorProps()

  return (
    <div className={cx(styles.componentSlider, layoutClassName)}>
      <div
        ref={elementRef}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={styles.sliderContainer}
      >
        <CursorTooltip isHidden={hasDragged} layoutClassName={styles.cursorTooltipLayout} {...{ x, y, isHovering }} />
        <ul ref={sliderRef} className={cx(styles.slider, 'keen-slider', sliderDisabled && styles.sliderDisabled)}>
          {Object.entries(items).map(([slug, { title, description, image, isSelected }], i) => {
            const isCurrentSlide = currentSlide === i

            return (
              <li key={slug} className={cx(styles.slideContainer, isCurrentSlide && styles.isCurrentSlide, 'keen-slider__slide')}>
                <ProofPointsCard layoutClassName={styles.cardLayout} {...{ slug, title, description, image, isSelected, isCurrentSlide }} />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

function ProofPointsCard({ slug, title, description, image, isSelected, isCurrentSlide, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const { toggleUserSelection, isMutating } = useMySelectionMutations()

  return (
    <div className={cx(styles.componentCard, layoutClassName)}>
      <div className={styles.textContainer}>
        <div className={cx(styles.titleContainer, isCurrentSlide && styles.isCurrentSlide)}>
          <HeadingMd h={4} {...{ title }} />
        </div>
        {description && (
          <p className={cx(styles.description, isCurrentSlide && styles.isCurrentSlide)}>
            {description}
          </p>
        )}
        <div className={styles.buttonContainer}>
          <SkillsMatchButtonToggleTransparent
            onClick={_ => handleOnClick(slug)}
            id={slug}
            label={__`skills-match-button-label-interested`}
            isProcessing={isMutating}
            isActive={isSelected}
          />
        </div>
      </div>

      {image && (
        <div className={cx(styles.imageContainer, isCurrentSlide && styles.isCurrentSlide)}>
          <ImageCover aspectRatio={5 / 7} layoutClassName={styles.imageLayout} {...{ image }} />
        </div>
      )}
    </div>
  )

  function handleOnClick(slug) {
    toggleUserSelection({ type: 'proofPoint', id: slug })
  }
}

function CursorTooltip({ x, y, isHovering, isHidden, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const [isIdle, setIsIdle] = React.useState(false)

  const style = useSpring({
    x: x - 80,
    y: y - 80,
    opacity: isHidden
      ? 0
      : isHovering
        ? 1
        : 0,
    scale: isHovering ? 1 : 0.4,
    config: { mass: 1, tension: 650, friction: 45 },
  })

  React.useEffect(
    () => {
      const timer = setTimeout(
        () => {
          setIsIdle(true)
        },
        2500
      )
      return () => clearTimeout(timer)
    },
    []
  )

  return (
    <animated.div className={cx(styles.componentCursorTooltip, layoutClassName)} {...{ style }}>
      <div className={styles.iconContainer}>
        <Icon icon={dragIcon} />
      </div>
      <div className={cx(styles.tooltip, isIdle && styles.isIdle)}>
        <p>{__`drag-tooltip`}</p>
      </div>
    </animated.div>
  )
}

function useCursorProps() {
  const elementRef = React.useRef(null)
  const [cursorCoords, setCursorCoords] = React.useState({ x: 0, y: 0 })
  const [relativeCursorCoords, setRelativeCursorCoords] = React.useState({ x: 0, y: 0 })
  const [isHovering, setIsHovering] = React.useState(false)
  const { x, y } = relativeCursorCoords

  React.useEffect(
    () => {
      window.addEventListener('mousemove', handleMouseMove)

      return () => window.removeEventListener('mousemove', handleMouseMove)

      function handleMouseMove(e) {
        setCursorCoords({ x: e.clientX, y: e.clientY })
      }
    },
    []
  )

  React.useEffect(
    () => {
      handleResize()
      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)

      function handleResize() {
        const { x: clientX, y: clientY } = elementRef.current.getBoundingClientRect()
        const x = cursorCoords.x - clientX + 48
        const y = cursorCoords.y - clientY + 64

        setRelativeCursorCoords({ x, y })
      }
    },
    [cursorCoords]
  )

  return { elementRef, x, y, isHovering, setIsHovering }
}


function useKeenSliderRefs({ slidesNum }) {
  const isViewportSm = useMediaQuery(mediaStyles.viewportSm) ?? false
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false
  const isViewportXxl = useMediaQuery(mediaStyles.viewportXxl) ?? false

  const initialSlide = 0
  const slidesPerView = isViewportXxl
    ? 3
    : isViewportLg
      ? 2
      : isViewportMd
        ? 1
        : isViewportSm
          ? 2
          : 1

  const sliderDisabled = slidesPerView === slidesNum
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [hasDragged, setHasDragged] = React.useState(false)

  const [sliderRef, instanceRef] = useKeenSlider({
    disabled: Boolean(sliderDisabled),
    initial: initialSlide,
    loop: false,
    range: {
      align: true,
    },
    mode: 'free-snap',
    slides: {
      origin: 'center',
      number: slidesNum,
      spacing: 16,
      perView: slidesPerView
    },
    slideChanged(s) {
      handleSlideChange(s.track.details.rel)
      trackInteraction(s, { action: 'dragged' })
    },
    dragStarted(s) {
      if (!hasDragged) setHasDragged(true)
      trackInteraction(s, { action: 'drag-started' })
    },
    dragEnded(s) {
      trackInteraction(s, { action: 'drag-ended' })
    },
  })

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef]
  )

  function handleSlideChange(idx) {
    setCurrentSlide(idx)
  }

  function trackInteraction(s, { action }) {
    pushToDataLayer({
      event: 'interaction',
      metadata: {
        interaction: { index: s.track.details.rel, type: 'slider', action }
      }
    })
  }

  return { sliderRef, currentSlide, hasDragged, sliderDisabled }
}


function useProofPointsData({ language }) {
  const reportError = useReportError()
  const { data: userSelection } = useSkillsMatchUserSelection({ language })
  const { data, isSuccess, isError, isFetching } = useQuery({
    queryKey: ['proofPointsData'],
    queryFn: () => fetchProofPointsData({ language, reportError }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const { proofPoints: proofPointsRaw } = data ?? {}
  const selectedProofPoints = userSelection?.proofPoints?.map(([slug]) => slug) ?? []
  const proofPoints = proofPointsRaw && Object.fromEntries(
    Object.entries(proofPointsRaw)
      .map(([slug, data]) => (
        [slug, ({ ...data, isSelected: selectedProofPoints.includes(slug) })]
      ))
  )

  return { proofPoints, isSuccess, isError, isFetching }
}

async function fetchProofPointsData({ language, reportError }) {
  try {
    return fetchWithResponseHandler(routeMap.api.v1.skillsMatch.proofPointsData(), {
      method: 'POST',
      body: JSON.stringify({ language })
    })
  } catch (e) {
    reportError(e)
  }
}
